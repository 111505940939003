import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { Popover, Transition } from "@headlessui/react";
import logo from "../resource/logo.png";

import {
  HomeIcon,
  EnvelopeIcon,
  QuestionMarkCircleIcon,
  ShoppingBagIcon,
  PresentationChartLineIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/outline";
import { Menu2, ShoppingCart } from "tabler-icons-react";
function TopNav() {
  return (
    <div className="">
      <HamburgerMenu />
    </div>
  );
}
const solutions = [
  {
    name: "Home",
    description: "Home to all your energy needs",
    href: "/",
    icon: HomeIcon,
  },
  {
    name: "Shop",
    description: "Browse all our great products",
    href: "/categories",
    icon: ShoppingBagIcon,
  },
  {
    name: "Monthly Subscriptions",
    description: "Set up re-occuring orders monthly",
    href: "/subscribe",
    icon: ArrowPathIcon,
  },
  {
    name: "About",
    description: "Learn more about your favorite energy brand",
    href: "/about",
    icon: PresentationChartLineIcon,
  },
  {
    name: "FAQs",
    description: "Got Questions? We got answers",
    href: "/faq",
    icon: QuestionMarkCircleIcon,
  },
  {
    name: "Contact Us",
    description: "Need to get a hold of us? We have an easy solution for you",
    href: "/contact",
    icon: EnvelopeIcon,
  },
];

function HamburgerMenu() {
  const navigate = useNavigate();
  return (
    <Popover className="relative">
      <div className="flex justify-between items-center h-14 md:h-20 lg:h-20 p-4 bg-inherit">
        <Popover.Button className="rounded bg-inherit px-2 py-0 text-xs font-semibold text-blue shadow-sm hover:bg-indigo-100">
          <Menu2 className="h-8 w-10 lg:h-10 lg:w-12" aria-hidden="true" />
        </Popover.Button>
        <button onClick={() => navigate("/")}>
          <img
            className="h-10 md:h-12 lg:h-14"
            alt="Big Blue Logo"
            src={logo}
          />
        </button>
        <button
          onClick={() => navigate("/checkout")}
          className="rounded bg-inherit h-10 w-14 md:w-16 md:h-12 lg:w-16 lg:h-12 px-3 lg:px-2 py-0 text-xs font-semibold text-blue shadow-sm hover:bg-indigo-100"
        >
          <ShoppingCart
            className="h-8 w-8 md:h-10 md:w-10 lg:h-10 lg:w-12"
            aria-hidden="true"
          />
        </button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute left-1/2 z-20 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
          {({ close }) => (
            <div className="w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
              <div className="p-4">
                {solutions.map((item) => (
                  <div
                    key={item.name}
                    className="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50"
                  >
                    <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                      <item.icon
                        className="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div>
                      <button
                        onClick={() => {
                          navigate(item.href);
                          close();
                        }}
                        className="font-semibold text-gray-900"
                      >
                        {item.name}
                        <span className="absolute inset-0" />
                      </button>
                      <p className="mt-1 text-gray-600">{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}

export default TopNav;
